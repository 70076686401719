angular.module(config.app.name).component('signature', {
    templateUrl: 'components/procedure/components/signature.component.html',
    bindings: {
        procedureId: '<',
        procedureType: '<',
        canGoBack: '<',
        getData: '&',
        setData: '&'
    },
    controller: function ($element, $scope, $timeout, $_procedures, toast) {
        var self = this;

        self.view = {
            title: 'Signature',
            icon: 'edit_document',
            buttons: {
                isSignaturePage: true
            }
        };

        self.step = 0;
        self.signed = false;
        self.documentsToSign = [];
        self.signatureURL = null;
        self.iframeLoaded = null;

        self.$onInit = function () {
            self.getData({
                type: ['proxyProcedure', 'concernedPerson', 'user'],
                cb: (data) => {
                    self.proxyProcedure = data[0];
                    self.owner = data[1];
                    let user = data[2];
                    if (self.proxyProcedure === true) {
                        if (user && user.phone) {
                            self.phone = user.phone;
                        }
                    } else if (self.owner && self.owner.phone) {
                        self.phone = self.owner.phone;
                    }
                }
            });
            self.getSigned();
        };

        self.getSigned = function (verifyOnly = false) {
            $_procedures.getSigned(self.procedureId).then((res) => {
                self.signed = res.signed;
                self.setData({
                    type: 'signed',
                    data: {signed: self.signed}
                });
                if (self.signed === true) {
                    self.step = 3;
                } else if (verifyOnly !== true) {
                    getDocumentsToSign();
                }
                $timeout(() => $scope.$apply());
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        function getIframeContentLoad() {
            let iframe = document.getElementById('iframe-sign');
            iframe.onload = function () {
                self.iframeLoaded = true;
                $timeout(() => $scope.$apply());
            };
        }

        function getDocumentsToSign() {
            $_procedures.getDocuments(self.procedureId, self.procedureType, {toSign: true}).then((res) => {
                self.documentsToSign = res.Documents;
                self.signatureURL = self.documentsToSign[0].signatureURL;
                self.setData({
                    type: 'documentsToSign',
                    data: {
                        documentsToSign: self.documentsToSign
                    }
                });
                if (self.proxyProcedure === true) { // show the sign iframe
                    self.step = 1;
                    $timeout(() => getIframeContentLoad(), 350);
                } else { // goto qrcode & sms page
                    self.step = 2;
                }
                $timeout(() => $scope.$apply());
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }

        self.sendSignatureLink = function () {
            $_procedures.sendSignatureLink(self.procedureId, self.procedureType, self.phone).then((res) => {
                if (res) {
                    toast.show('Le lien de signature a bien été envoyé par SMS au ' + self.phone, 'success');
                }
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.next = function () {
            self.setData({
                type: 'signature',
                data: {signed: self.signed}
            });
        };

        self.back = function () {
            $element[0].dispatchEvent(new CustomEvent('navigation', {
                detail: 'back'
            }));
        };
    }
}).config(function ($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'https://staging-app.yousign.com/**'
    ]);
});